import { QueryClient } from "react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      staleTime: 60 * 1000, // 1 minute
      cacheTime: 20 * 60 * 1000, // 20 minutes
    },
  },
});
