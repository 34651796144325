import { format as formatTz } from "date-fns-tz";
import { format, formatDistanceToNow, parseJSON } from "date-fns";

export const DATE_FORMAT = "MM/dd/yyyy";
export const TIME_FORMAT = "h:mm aa";
export const DATETIME_FORMAT = `MMM dd, yyyy ${TIME_FORMAT} zzz`;
export const DATETIME_FORMAT_SHORT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const formatDate = (date: Date | null | undefined): string => {
  if (date) {
    return format(date, DATE_FORMAT);
  }
  return "";
};

export const formatDateString = (json?: string | null): string => {
  if (json === undefined || json === null) {
    return "";
  }
  return format(parseDate(json), DATE_FORMAT);
};

export const formatDateTimeString = (json: string): string => {
  return formatTz(parseDate(json), DATETIME_FORMAT);
};

export const formatDateTimeDistanceString = (json: string): string => {
  return formatDistanceToNow(parseDate(json));
};

export const parseDate = (json: string): Date => {
  return parseJSON(json);
};
