import { Spin } from "antd";
import React, { FunctionComponent } from "react";

export const Loading: FunctionComponent = () => {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flex: 1,
        height: "100%",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Spin />
    </div>
  );
};
