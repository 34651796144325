import axios from "axios";
import { useMutation } from "react-query";
import { Bid } from "~/bids/types";
import { API_PREFIX, API_ROUTE_BIDS } from "~/util/api";

const enterBid = async ({
  bid,
  token,
}: {
  bid: Bid;
  token: string;
}): Promise<boolean> => {
  // no JWT
  const resp = await axios.post(`${API_ROUTE_BIDS}?token=${token}`, bid, {
    baseURL: API_PREFIX,
    timeout: 30000,
  });
  return resp.data;
};

export default function useBidEnter() {
  return useMutation(enterBid);
}
