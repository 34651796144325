import { ConfigProps } from "antd/es/notification";

// TABLES =======================================
export const DEFAULT_TABLE_PAGE_SIZE = 50;
export const DEFAULT_TABLE_PAGINATION = {
  defaultPageSize: DEFAULT_TABLE_PAGE_SIZE,
  showSizeChanger: true,
};
export const DEFAULT_TABLE_HEIGHT = "calc(100vh - 240px)";

// NOTIFICATIONS ================================
export const DEFAULT_NOTIFICATION_CONFIG: ConfigProps = {
  placement: "topRight",
  top: 55,
  duration: 2,
};
