import React, { FunctionComponent } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { LOGIN_ROUTE } from "~/constants/routes";
import auth from "~/util/auth";

export const PrivateRoute: FunctionComponent<RouteProps> = ({
  children,
  component: ChildComponent,
  location,
  ...rest
}) => {
  if (!auth.loggedIn) {
    return (
      <Redirect
        to={{
          pathname: LOGIN_ROUTE,
          state: { from: location },
        }}
      />
    );
  }

  if (children) {
    return <Route {...rest}>{children}</Route>;
  }

  if (ChildComponent) {
    return <Route {...rest} component={ChildComponent} />;
  }

  return <Route />;
};
