import jwtDecode from "jwt-decode";

const STORAGE_KEY_TOKEN = "spotshipper-token";

class Auth {
  get loggedIn(): boolean {
    return this.token !== null && this.token.length > 0;
  }

  get token() {
    return window.sessionStorage.getItem(STORAGE_KEY_TOKEN);
  }

  get email(): string {
    if (this.token) {
      const payload: any = jwtDecode(this.token);
      if (payload?.email) {
        return payload?.email;
      }
    }
    return "Unknown";
  }

  get name(): string {
    if (this.token) {
      const payload: any = jwtDecode(this.token);
      return payload?.name ?? payload?.email ?? "Unknown";
    }
    return "Unknown";
  }

  login(token: string) {
    window.sessionStorage.setItem(STORAGE_KEY_TOKEN, token);
    return true;
  }

  logout() {
    window.sessionStorage.removeItem(STORAGE_KEY_TOKEN);
    return true;
  }
}

const auth = new Auth();
export default auth;
