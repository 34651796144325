/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  addDays,
  addMonths,
  addYears,
  endOfMonth,
  format as formatDate,
  getDate,
  getDay,
  getHours,
  getMinutes,
  getMonth,
  getSeconds,
  getWeek,
  getYear,
  isAfter,
  isValid,
  parse as parseDate,
  setDate,
  setHours,
  setMinutes,
  setMonth,
  setSeconds,
  setYear,
  startOfWeek,
} from "date-fns/esm";
import { enUS } from "date-fns/esm/locale";

type GenerateConfig<DateType> = {
  // Get
  getWeekDay: (value: DateType) => number;
  getSecond: (value: DateType) => number;
  getMinute: (value: DateType) => number;
  getHour: (value: DateType) => number;
  getDate: (value: DateType) => number;
  getMonth: (value: DateType) => number;
  getYear: (value: DateType) => number;
  getNow: () => DateType;
  getFixedDate: (fixed: string) => DateType;
  getEndDate: (value: DateType) => DateType;

  // Set
  addYear: (value: DateType, diff: number) => DateType;
  addMonth: (value: DateType, diff: number) => DateType;
  addDate: (value: DateType, diff: number) => DateType;
  setYear: (value: DateType, year: number) => DateType;
  setMonth: (value: DateType, month: number) => DateType;
  setDate: (value: DateType, date: number) => DateType;
  setHour: (value: DateType, hour: number) => DateType;
  setMinute: (value: DateType, minute: number) => DateType;
  setSecond: (value: DateType, second: number) => DateType;

  // Compare
  isAfter: (date1: DateType, date2: DateType) => boolean;
  isValidate: (date: DateType) => boolean;

  locale: {
    getWeekFirstDay: (locale: string) => number;
    getWeekFirstDate: (locale: string, value: DateType) => DateType;
    getWeek: (locale: string, value: DateType) => number;

    format: (locale: string, date: DateType, format: string) => string;

    /** Should only return validate date instance */
    parse: (locale: string, text: string, formats: string[]) => DateType | null;

    /** A proxy for getting locale with moment or other locale library */
    getShortWeekDays?: (locale: string) => string[];
    /** A proxy for getting locale with moment or other locale library */
    getShortMonths?: (locale: string) => string[];
  };
};

const localeParse = (format: string) => {
  return format
    .replace(/Y/g, "y")
    .replace(/D/g, "d")
    .replace(/gggg/, "yyyy")
    .replace(/g/g, "G")
    .replace(/([Ww])o/g, "wo");
};

const generateConfig: GenerateConfig<Date> = {
  // get
  getNow: () => new Date(),
  getFixedDate: (string) => new Date(string),
  getEndDate: (date) => endOfMonth(date),
  getWeekDay: (date) => getDay(date),
  getYear: (date) => getYear(date),
  getMonth: (date) => getMonth(date),
  getDate: (date) => getDate(date),
  getHour: (date) => getHours(date),
  getMinute: (date) => getMinutes(date),
  getSecond: (date) => getSeconds(date),

  // set
  addYear: (date, diff) => addYears(date, diff),
  addMonth: (date, diff) => addMonths(date, diff),
  addDate: (date, diff) => addDays(date, diff),
  setYear: (date, year) => setYear(date, year),
  setMonth: (date, month) => setMonth(date, month),
  setDate: (date, num) => setDate(date, num),
  setHour: (date, hour) => setHours(date, hour),
  setMinute: (date, minute) => setMinutes(date, minute),
  setSecond: (date, second) => setSeconds(date, second),

  // Compare
  isAfter: (date1, date2) => isAfter(date1, date2),
  isValidate: (date) => isValid(date),

  locale: {
    getWeekFirstDay: (locale) => {
      return enUS.options?.weekStartsOn ?? 0;
    },
    getWeekFirstDate: (locale, date) => {
      return startOfWeek(date, { locale: enUS });
    },
    getWeek: (locale, date) => {
      return getWeek(date, { locale: enUS });
    },
    getShortWeekDays: (locale) => {
      const clone = enUS;
      return Array.from({ length: 7 }).map((_, i) =>
        clone?.localize?.day(i, { width: "short" })
      );
    },
    getShortMonths: (locale) => {
      const clone = enUS;
      return Array.from({ length: 12 }).map((_, i) =>
        clone?.localize?.month(i, { width: "abbreviated" })
      );
    },
    format: (locale, date, format) => {
      if (!isValid(date)) {
        return "";
      }
      return (
        formatDate(date, localeParse(format), {
          locale: enUS,
        }) ?? ""
      );
    },
    parse: (locale, text, formats) => {
      for (let i = 0; i < formats.length; i += 1) {
        const format = localeParse(formats[i]);
        const date = parseDate(text, format, new Date(), {
          locale: enUS,
        });
        if (isValid(date)) {
          return date;
        }
      }
      return null;
    },
  },
};

export default generateConfig;
