export const HOME_ROUTE = "/";
export const LOGIN_ROUTE = "/login";

export const SHIPMENTS_ROUTE = "/shipments";
export const SHIPMENTS_CREATE_ROUTE = "/shipments/new";
export const SHIPMENTS_DETAIL_ROUTE = "/shipments/:id";

export const BID_ENTRY_ROUTE = "/bid_entry";

export const ADDRESSES_ROUTE = "/addresses";
export const ADDRESSES_CREATE_ROUTE = "/addresses/new";
export const ADDRESSES_DETAIL_ROUTE = "/addresses/:id";

export const COMMODITIES_ROUTE = "/commodities";
export const COMMODITIES_CREATE_ROUTE = "/commodities/new";
export const COMMODITIES_DETAIL_ROUTE = "/commodities/:id";

export const CONTACTS_ROUTE = "/contacts";
export const CONTACTS_CREATE_ROUTE = "/contacts/new";
export const CONTACTS_DETAIL_ROUTE = "/contacts/:id";

export const ANALYTICS_ROUTE = "/analytics";

export const SETTINGS_ROUTE = "/settings";
export const DATA_EXPORT_ROUTE = "/export";
