import { Alert, Button, Card, Col, Form, Input, Row, Typography } from "antd";
import React, { FunctionComponent, useState } from "react";
import { useHistory } from "react-router-dom";
import useBidEnter from "~/bids/useBidEnter";
import { Container } from "~/components/Container";
import DatePicker from "~/components/DatePicker";
import { APP_NAME } from "~/constants/strings";
import { DATE_FORMAT } from "~/util/date";
import { useQueryParams } from "~/util/useQueryParams";

export const BidEntry: FunctionComponent = () => {
  const history = useHistory();
  const query = useQueryParams();
  const [success, setSuccess] = useState(false);
  const { mutateAsync, isLoading, isError, error } = useBidEnter();
  const [form] = Form.useForm();

  if (isError) {
    console.error(error);
  }

  return (
    <Container centered>
      <Typography.Title level={3}>{APP_NAME}</Typography.Title>

      <Row style={{ width: "100%" }} justify="center">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 18 }}
          lg={{ span: 16 }}
          xl={{ span: 14 }}
          xxl={{ span: 10 }}
        >
          <Card title="Bid Entry" style={{ width: "100%" }}>
            {success ? (
              <>
                <Alert
                  type="success"
                  message="Success!"
                  description={
                    <p>
                      Your bid has been submitted successfully. Thanks for using{" "}
                      {APP_NAME}!
                    </p>
                  }
                  showIcon
                />
              </>
            ) : (
              <Form
                form={form}
                onFinish={async (bid) => {
                  const token = query.get("token");
                  if (token) {
                    const result = await mutateAsync({ bid, token });
                    if (result) {
                      setSuccess(true);
                      history.replace({ search: "" });
                    }
                  }
                }}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                colon={false}
                requiredMark
              >
                <Form.Item
                  name="scac"
                  label="SCAC"
                  rules={[{ required: true, message: "SCAC is required" }]}
                  tooltip={{ title: "Standard Carrier Alpha Code" }}
                >
                  <Input placeholder="AAAA" />
                </Form.Item>

                <Form.Item
                  name="rate"
                  label="All-In Rate"
                  rules={[
                    { required: true, message: "Rate is required" },
                    {
                      message: "Rate must be numeric (no commas)",
                      pattern: /^\d+(\.\d{1,2})?$/,
                    },
                  ]}
                >
                  <Input prefix="$" placeholder="1234.50" />
                </Form.Item>

                <Form.Item
                  name="date_estimated_pickup"
                  label="Estimated Pickup"
                >
                  <DatePicker format={DATE_FORMAT} />
                </Form.Item>

                <Form.Item
                  name="date_estimated_delivery"
                  label="Estimated Delivery"
                >
                  <DatePicker format={DATE_FORMAT} />
                </Form.Item>

                <Form.Item name="comment" label="Comments">
                  <Input.TextArea />
                </Form.Item>

                <Form.Item label=" ">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {isError && (
                      <Alert
                        type="error"
                        message="Something went wrong while submitting your bid!"
                        style={{ marginBottom: 20 }}
                      />
                    )}

                    <Button
                      htmlType="submit"
                      type="primary"
                      loading={isLoading}
                      style={{ flexGrow: 1 }}
                    >
                      Submit Bid
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
