import { notification } from "antd";
import React, { FunctionComponent, Suspense } from "react";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Route, Router, Switch } from "react-router-dom";
import { BidEntry } from "~/bids/BidEntry";
import { Loading } from "~/components/Loading";
import { PrivateRoute } from "~/components/PrivateRoute";
import { DEFAULT_NOTIFICATION_CONFIG } from "~/constants/config";
import { history } from "~/constants/history";
import { BID_ENTRY_ROUTE, LOGIN_ROUTE } from "~/constants/routes";
import { queryClient } from "~/util/queryClient";

const Login = React.lazy(() => import("~/user/Login"));
const Home = React.lazy(() => import("~/app/Home"));

export const App: FunctionComponent = () => {
  notification.config(DEFAULT_NOTIFICATION_CONFIG);

  return (
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path={LOGIN_ROUTE} component={Login} />
            <Route exact path={BID_ENTRY_ROUTE} component={BidEntry} />
            <PrivateRoute path="/:app?" component={Home} />
          </Switch>
        </Suspense>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
