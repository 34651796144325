import axios from "axios";
import { history } from "~/constants/history";
import { LOGIN_ROUTE } from "~/constants/routes";
import auth from "~/util/auth";

export const API_PREFIX = "/api";
export const API_ROUTE_LOGIN = "users/login";
export const API_ROUTE_ADDRESSES = "addresses/";
export const API_ROUTE_BIDS = "bids/";
export const API_ROUTE_COMMODITIES = "commodities/";
export const API_ROUTE_CONTACTS = "contacts/";
export const API_ROUTE_DOCUMENTS = "documents/";
export const API_ROUTE_DOCUMENT_TYPES = "document_types/";
export const API_ROUTE_SHIPMENTS = "shipments/";
export const API_ROUTE_SHIPMENT_INCOTERMS = "shipment_incoterms/";
export const API_ROUTE_SHIPMENT_MODES = "shipment_modes/";
export const API_ROUTE_SHIPMENT_STATUSES = "shipment_statuses/";
export const API_ROUTE_SHIPMENT_EXPORT = "shipments/export.csv";

export const unauthenticatedApi = axios.create({
  baseURL: API_PREFIX,
  timeout: 30000,
});

export const api = axios.create({
  baseURL: API_PREFIX,
  timeout: 30000,
});

export const isApiError = axios.isAxiosError;

api.interceptors.request.use(function (config) {
  if (config.headers === undefined) {
    config.headers = {};
  }
  config.headers.Authorization = `Bearer ${auth.token}`;
  return config;
});

api.interceptors.response.use(
  (resp) => resp,
  (error) => {
    if (error?.response?.status === 401) {
      auth.logout();
      history.push(LOGIN_ROUTE, { from: window.location.pathname });
    }
    throw error;
  }
);
