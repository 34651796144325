import React, { FunctionComponent } from "react";

interface Props {
  centered?: boolean;
  children: React.ReactNode;
}

export const Container: FunctionComponent<Props> = ({
  centered = false,
  children,
}) => {
  return (
    <div
      style={{
        alignItems: centered ? "center" : "flex-start",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: centered ? "center" : "flex-start",
        padding: "1rem",
        width: "100%",
      }}
    >
      {children}
    </div>
  );
};
